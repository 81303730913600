import { Route, Routes } from 'react-router-dom';
import Buying from './pages/Buying';
import Devices from './pages/Devices';
import Flavours from './pages/Flavours';
import Fluids from './pages/Fluids';
import Manufacturers from './pages/Manufacturers';
import Rating from './pages/Rating';
import Start from './pages/Start';
import './styles/App.scss';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Start/>}/>

      <Route path='/fluids' element={<Fluids/>}/>
      <Route path='/fluids/:id' element={<Fluids/>}/>
      
      <Route path='/flavours' element={<Flavours/>}/>
      <Route path='/flavours/:id' element={<Flavours/>}/>

      <Route path='/manufacturers' element={<Manufacturers/>}/>
      <Route path='/manufacturers/:id' element={<Manufacturers/>}/>

      <Route path='/devices' element={<Devices/>}/>
      <Route path='/devices/:id' element={<Devices/>}/>

      <Route path='/buying' element={<Buying/>}/>
      <Route path='/rating' element={<Rating/>}/>
      
      <Route path='*' element={<Start/>}/>
    </Routes>
  );
}

export default App;
