import React from 'react'
import { Link } from 'react-router-dom'

const LogoStart = () => {
  return (
    <Link to='/' className='logo-position logo'><span className='logo-first'>S</span>MOKE STORY</Link>
  )
}

export default LogoStart
