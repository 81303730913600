import React from 'react';

const Back = (props) => {
  return (
    <div>
        {props.isStart > 0
            ? <div className='back back-start' />
            : <div className='back back-rest' />
        }
    </div>
  )
}

export default Back
