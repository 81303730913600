import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

const FluidElement = (props) => {
  const router = useNavigate();

  const [isManufacturer, setIsManufacturer] = useState();

  function color_rating() {
    if(props.fluid.Rating >= 0 && props.fluid.Rating < 4.1){
      return(<span className='fluid-element-rat fluid-element-text fluid-element-color-red'>{props.fluid.Rating}</span>);
    }else if(props.fluid.Rating > 2 && props.fluid.Rating < 6.1){
      return(<span className='fluid-element-rat fluid-element-text fluid-element-color-orange'>{props.fluid.Rating}</span>);
    }else if(props.fluid.Rating > 4 && props.fluid.Rating < 7.1){
      return(<span className='fluid-element-rat fluid-element-text fluid-element-color-yellow'>{props.fluid.Rating}</span>);
    }else if(props.fluid.Rating > 6 && props.fluid.Rating < 8.5){
      return(<span className='fluid-element-rat fluid-element-text fluid-element-color-lightgreen'>{props.fluid.Rating}</span>);
    }else{
      return(<span className='fluid-element-rat fluid-element-text fluid-element-color-green'>{props.fluid.Rating}</span>);
    }
  }

  const rivModal = () =>{
    if(!isManufacturer){
      router(`/fluids/${props.fluid.id}`)
      props.setVisible('flex')
      setIsManufacturer(!isManufacturer)
      document.body.classList.add('modal-open')
    }
  }
  return (
    <div className='fluid-element-panel' onClick={rivModal}>
      <div className='fluid-element-number-plate fluid-element-state-plate'>
        <span className='fluid-element-number fluid-element-text'>{props.num}</span>
      </div>
      <div className='fluid-element-name-plate fluid-element-state-plate'>
        <span className='fluid-element-name fluid-element-text'>{props.fluid.Name}</span>
        <span className='fluid-element-tnvp fluid-element-text'>{props.fluid.Taste}</span>
      </div>
      <div className='fluid-element-img-plate fluid-element-state-plate'>
        <img className='fluid-element-img' src={'https://db.smoke-story.site/images/fluids/'+props.fluid.Image} alt={props.fluid.Name}></img>
      </div>
      <div className='fluid-element-nic-plate fluid-element-state-plate'>
        <span className='fluid-element-tnvp fluid-element-text'>{props.fluid.Nicotine}</span>
      </div>
      <div className='fluid-element-vol-plate fluid-element-state-plate'>
        <span className='fluid-element-tnvp fluid-element-text'>{props.fluid.Volume} мл</span>
      </div>
      <div className='fluid-element-prc-plate fluid-element-state-plate'>
        <span className='fluid-element-tnvp fluid-element-text'>{props.fluid.Price}</span>
      </div>
      {/*mobile*/}
      <div className='fluid-element-ntm-plate-mobile fluid-element-state-plate'>
        <span className='fluid-element-name fluid-element-text'>{props.fluid.Name}</span>
        <span className='fluid-element-tnvp fluid-element-text'>{props.fluid.Taste}</span>
        <Link to={'/manufacturers/'+props.fluid.ManufacturerId} onClick={() => {setIsManufacturer(!isManufacturer); document.body.classList.add('modal-open')}} className='fluid-element-man-mobile fluid-element-text' style={{color: props.fluid.ManufacturerColor}}>{props.fluid.Manufacturer}</Link>
      </div>
      {/*mobile*/}<div className='fluid-element-invp-plate-mobile fluid-element-state-plate'>
        <img className='fluid-element-img' src={'https://db.smoke-story.site/images/fluids/'+props.fluid.Image} alt={props.fluid.Name}></img>
        <span className='fluid-element-tnvp fluid-element-text'>{props.fluid.Nicotine}</span>
        <span className='fluid-element-tnvp fluid-element-text'>{props.fluid.Volume}</span>
        <span className='fluid-element-tnvp fluid-element-text'>{props.fluid.Price}</span>
      </div>
      <div className='fluid-element-dsc-plate fluid-element-state-plate'>
        <span className='fluid-element-dsc fluid-element-text'>{props.fluid.Description}</span>
      </div>
      {/*mobile*/}<div className='fluid-element-rb-plate-mobile fluid-element-state-plate'>
        {color_rating()}
        <span className='fluid-element-buy fluid-element-text'>{props.fluid.Buying}</span>
      </div>
      <div className='fluid-element-rat-plate fluid-element-state-plate'>
        {color_rating()}
      </div>
      <div className='fluid-element-buy-plate fluid-element-state-plate'>
        <span className='fluid-element-buy fluid-element-text'>{props.fluid.Buying}</span>
      </div>
      <Link to={'/manufacturers/'+props.fluid.ManufacturerId} onClick={() => {setIsManufacturer(!isManufacturer); document.body.classList.add('modal-open')}} className='fluid-element-man fluid-element-text' style={{color: props.fluid.ManufacturerColor}}><b>{props.fluid.Manufacturer}</b></Link>
    </div>
  )
}

export default FluidElement
