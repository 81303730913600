import { HandySvg } from 'handy-svg'
import React from 'react'
import { Link } from 'react-router-dom'
import SvgI from '../../svg/plus.svg';

const PlusButton = () => {
  return (
    <div className='v-center'><Link to='/fluids/new' onClick={document.body.classList.add('modal-open')}><HandySvg src={SvgI} className="plus-button"/></Link></div>
  )
}

export default PlusButton
