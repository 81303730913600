import React, { useState } from 'react'
import { Transition } from 'react-transition-group'

const USelect = ({children, className, ...props}) => {
  const [triangle, setTriangle] = useState(false);
  return (
    <div className={className}>
        <div className='select-wrapper-plate'>
            <Transition in={triangle} timeout={300}>
                {
                    state=><div className={`select-wrapper-triangle ${state}`}></div>
                }
            </Transition>
        </div>
        <select className='ui-select' onClick={() => setTriangle(!triangle)} {...props}>
            {children}
        </select>
    </div>
  )
}

export default USelect
