import React from 'react'
import { HandySvg } from 'handy-svg'
import { Link } from 'react-router-dom'
import SvgA from '../../svg/aroma.svg';
import SvgB from '../../svg/bottle.svg';
import SvgS from '../../svg/smoke.svg';

const BottomButtons = () => {
  return (
    <div className='panelCenter-bottom'>
      <div className='bottomButtons-panel bottomButtons-panel-position'>
        <Link to='/flavours' className='bottomButton'>
            <div className='bottomButton-back'></div>
            <HandySvg src={SvgA} className="bottomButton-svg bottomButton-svg-position"/>
            <div className='bottomButton-title'>Ароматизаторы</div>
            <div className='bottomButton-text'>Компоненты самозамесов</div>
        </Link>
        <Link to='/fluids' className='bottomButton'>
            <div className='bottomButton-back'></div>
            <HandySvg src={SvgB} className="bottomButton-svg2 bottomButton-svg2-position"/>
            <div className='bottomButton-title'>Жидкости</div>
            <div className='bottomButton-text'>Полный список жидкостей</div>
        </Link>
        <Link to='/devices' className='bottomButton'>
            <div className='bottomButton-back'></div>
            <HandySvg src={SvgS} className="bottomButton-svg bottomButton-svg-position"/>
            <div className='bottomButton-title'>Девайсы</div>
            <div className='bottomButton-text'>Все устройства</div>
        </Link>
      </div>
    </div>
  )
}

export default BottomButtons
