import React, { useEffect, useState } from 'react'
import Back from '../components/Back'
import Header from '../components/Header'
import Title from '../components/Title'
import Footer from '../components/Footer'
import ApiService from '../API/ApiService'
import Loader from '../components/Loader'
import { useFetching } from '../hooks/useFething'
import BuyingElementBlock from '../components/buying-page/BuyingElementBlock'

const Buying = () => {
  const [buyings, setBuyings] = useState([]);
  const [maxCount, setMaxCount] = useState([]);
  const [fetchBuyings, isBuyingsLoading, buyingError] = useFetching( async() => {
    const response = await ApiService.getBuying();
    setBuyings(response.data);
    setMaxCount(response.headers['max-count']);
  });

  useEffect(()=>{
    fetchBuyings();
    document.body.classList.remove('modal-open')
  }, [])

  return (
    <div>
        <Back isStart='0' />
        <Header/>
        <Title title='Покупки'/>
        {buyingError && 
            <h1>Произошла ошибка ${buyingError}</h1>
        }
        {isBuyingsLoading
          ?<Loader head={false}/>
          :<div><BuyingElementBlock buyings={buyings} maxCount={maxCount}/></div>
        }
        <Footer/>
    </div>
  )
}

export default Buying