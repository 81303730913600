import React from 'react'

const UToggle = ({...props}) => {
  return (
    <div className='ui-toggle'>
      <div className='ui-toggle-text'><span>🌱</span><span>🧂</span></div>
      <input type="checkbox" class="ui-toggle-element" {...props}></input>
    </div>
  )
}

export default UToggle
