import React from 'react'
import DevicesElement from './DevicesElement'
import {useNavigate} from 'react-router-dom';

const DevicesElementBlock = ({devices, elementBlockVisible, setVisible}) => {
  const router = useNavigate();
  return (
    <div className='pad pad2' style={{display: elementBlockVisible}}>
      <div className='devices-elements-block-plate'>
        {devices.map(device => 
            <DevicesElement device={device} key={device.id} setVisible={setVisible}/> 
        )}
        <div className='devices-element' onClick={()=> router(`/devices/new`, setVisible('flex'), document.body.classList.add('modal-open'))}>
          <div className='devices-add_element-round'>
            <span className='devices-add_element-plus'>+</span>
          </div>
          <div className='devices-add_element-text'>Добавить элемент</div>
        </div>
      </div>
    </div>
  )
}

export default DevicesElementBlock
