import React from 'react'
import {useNavigate} from 'react-router-dom';

const DevicesElement = (props) => {
  const router = useNavigate();
  function stars() {
    switch (props.device.Rating) {
        case '1':
            return <div className='devices-element-star'><span>★</span><span>☆</span><span>☆</span><span>☆</span><span>☆</span></div> 
        case '2':
            return <div className='devices-element-star'><span>★</span><span>★</span><span>☆</span><span>☆</span><span>☆</span></div> 
        case '3':
            return <div className='devices-element-star'><span>★</span><span>★</span><span>★</span><span>☆</span><span>☆</span></div> 
        case '4':
            return <div className='devices-element-star'><span>★</span><span>★</span><span>★</span><span>★</span><span>☆</span></div> 
        case '5':
            return <div className='devices-element-star'><span>★</span><span>★</span><span>★</span><span>★</span><span>★</span></div> 
        default:
            return <div className='devices-element-star'><span>☆</span><span>☆</span><span>☆</span><span>☆</span><span>☆</span></div> 
    }
  }
  return (
    <div className='devices-element' onClick={()=> router(`/devices/${props.device.id}`, props.setVisible('flex'), document.body.classList.add('modal-open'))}>
      <img className='devices-element-img' src={'https://db.smoke-story.site/images/devices/'+props.device.Image} alt={props.device.Name}></img>
      <div className='devices-element-name'>{props.device.Name}</div>
      <div className='devices-element-star-plate'>{stars()}</div>
      <div className='devices-element-text'>{props.device.Description}</div>
    </div>
  )
}

export default DevicesElement
