import React, { useEffect, useState } from 'react'

const UStars = ({stars, setStars}) => {
  const [tagPlate, setTagPlate] = useState({Star1: '☆', Star2:'☆', Star3:'☆', Star4:'☆', Star5:'☆'});

    useEffect(()=>{
        switch (stars) {
            case 1:
                setTagPlate({Star1: '★', Star2:'☆', Star3:'☆', Star4:'☆', Star5:'☆'})
                break;
            case 2:
                setTagPlate({Star1: '★', Star2:'★', Star3:'☆', Star4:'☆', Star5:'☆'})
                break;
            case 3:
                setTagPlate({Star1: '★', Star2:'★', Star3:'★', Star4:'☆', Star5:'☆'})
                break;
            case 4:
                setTagPlate({Star1: '★', Star2:'★', Star3:'★', Star4:'★', Star5:'☆'})
                break;
            case 5:
                setTagPlate({Star1: '★', Star2:'★', Star3:'★', Star4:'★', Star5:'★'})
                break;
            default:
                setTagPlate({Star1: '☆', Star2:'☆', Star3:'☆', Star4:'☆', Star5:'☆'})
                break;
        }
    }, [stars])
        
  return (
    <div className='fluid-element-rat-plate fluid-element-state-plate' style={{margin: '0', cursor: 'pointer', minWidth: '125px', display: 'flex'}}>
        <div className='devices-element-star'>
            <span onClick={() => setStars(1)}>{tagPlate.Star1}</span>
            <span onClick={() => setStars(2)}>{tagPlate.Star2}</span>
            <span onClick={() => setStars(3)}>{tagPlate.Star3}</span>
            <span onClick={() => setStars(4)}>{tagPlate.Star4}</span>
            <span onClick={() => setStars(5)}>{tagPlate.Star5}</span>
        </div>
    </div>
  )
}

export default UStars
