import React from 'react'
import RatingElement from './RatingElement'

const RatingElementBlock = ({ratings}) => {
  return (
    <div className='pad pad2'>
      <div className='rating-elements-block-plate'>
        {ratings.map((rating, index) => 
            <RatingElement rating={rating} key={index} num={index+1}/> 
        )}
      </div>
    </div>
  )
}

export default RatingElementBlock
