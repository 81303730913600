import React from 'react'

const Decorate = () => {
  return (
    <div>
      <div className='h-center'> 
        <div className='padTitle padTitle-position'>
          <div className='smallTitle smallTitle-position'>MY FLAVOUR</div>
          <div className='bigTitle bigTitle-position'>VAPE</div>
        </div>
      </div> 
      
      <hr className='lLine lLine-position'/>
      <div className='decor-text decor-text-position'>Horoshilov Victor</div>
    </div>
  )
}

export default Decorate
