import React from 'react';
import Back from '../components/Back';
import Decorate from '../components/start-page/Decorate';
import TopButtons from '../components/start-page/TopButtons';
import PlusButton from '../components/start-page/PlusButton';
import BottomButtons from '../components/start-page/BottomButtons';
import MenuStart from '../components/start-page/Menu-start';

const Start = () => {
  return (
    <div>
      <Back isStart='1' />
      <Decorate/>
      <TopButtons/>
      <PlusButton/>
      <BottomButtons/>
      <MenuStart/>
    </div>
  )
}

export default Start;