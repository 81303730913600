import React from 'react'

const FlavoursHead = () => {
  return (
    <div className='pad'>
      <div className='pages-head-plate'>
        <div className='fluid-head-text fluid-head-sharp'>#</div>
        <div className='fluid-head-text fluid-head-name'>Имя/Вкус</div>
        <div className='fluid-head-text fluid-head-img'>Изображения</div>
        <div className='fluid-head-text fluid-head-dsc'>Описание</div>
        <div className='fluid-head-text fluid-head-buy' style={{display:'block'}}>Рейтинг</div>
      </div>
      {/*стили в fluid head (класс покупки указан для рейтинга здесь)*/}
    </div>
  )
}

export default FlavoursHead
