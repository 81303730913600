import React, { useEffect, useState } from 'react'
import Back from '../components/Back'
import Header from '../components/Header'
import Title from '../components/Title'
import Footer from '../components/Footer'
import ApiService from '../API/ApiService'
import Loader from '../components/Loader'
import { useFetching } from '../hooks/useFething'
import RatingElementBlock from '../components/rating-page/RatingElementBlock'
import RatingElementHead from '../components/rating-page/RatingElementHead'

const Rating = () => {
    const [ratings, setRatings] = useState([]);
    const [fetchRatings, isRatingsLoading, ratingError] = useFetching( async() => {
      const response = await ApiService.getRating();
      setRatings(response.data);
    });
  
    useEffect(()=>{
      fetchRatings();
      document.body.classList.remove('modal-open')
    }, [])
  
    return (
      <div>
          <Back isStart='0' />
          <Header/>
          <Title title='Оценки'/>
          <RatingElementHead/>
          
          {ratingError && 
              <h1>Произошла ошибка ${ratingError}</h1>
          }
          {isRatingsLoading
            ?<Loader head={true}/>
            :<div><RatingElementBlock ratings={ratings}/></div> 
          }
          <Footer/>
      </div>
    )
}

export default Rating
