import React from 'react'

const RatingElementHead = () => {
  return (
    <div className='pad'>
        <div className='rating-elements-block-header'>
            <span className='rating-elements-block-header-point rating-elements-block-header-point-left'>0</span>
            <div className='rating-elements-block-header-cube'>
                <hr className='rating-elements-block-header-line'></hr>
                <span className='rating-elements-block-header-point'>2</span>
            </div>
            <div className='rating-elements-block-header-cube'>
                <hr className='rating-elements-block-header-line'></hr>
                <span className='rating-elements-block-header-point'>4</span>
            </div>
            <div className='rating-elements-block-header-cube'>
                <hr className='rating-elements-block-header-line'></hr>
                <span className='rating-elements-block-header-point'>6</span>
            </div>
            <div className='rating-elements-block-header-cube'>
                <hr className='rating-elements-block-header-line'></hr>
                <span className='rating-elements-block-header-point'>8</span>
            </div>
            <span className='rating-elements-block-header-point rating-elements-block-header-point-right'>10</span>
        </div>
    </div>
  )
}

export default RatingElementHead
