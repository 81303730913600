import React, { useEffect, useState } from 'react'
import Back from '../components/Back'
import Header from '../components/Header'
import Title from '../components/Title'
import Footer from '../components/Footer'
import DevicesElementBlock from '../components/devices-page/DevicesElementBlock'
import ApiService from '../API/ApiService'
import Loader from '../components/Loader'
import { useFetching } from '../hooks/useFething'
import DevicesModal from '../components/devices-page/DevicesModal'

const Devices = () => {
  const [devices, setDevices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [loaderVisible, setLoaderVisible] = useState('flex');
  const [elementBlockVisible, setElementBlockVisible] = useState('none');
  const [modalVisible, setModalVisible] = useState('none');

  const [fetching, setFetching] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [fetchDevices, isDevicesLoading, deviceError] = useFetching( async() => {
    setLoaderVisible('flex');
    const responce = await ApiService.getDevices(15, currentPage);
    setDevices([...devices, ...responce.data]);
    setCurrentPage(prevState => prevState+1);
    setTotalCount(responce.headers['x-total-count']);
    setFetching(false);
    setLoaderVisible('none');
    setElementBlockVisible('flex');
  });

  const updateDevice = () => {
    setDevices([]);
    setCurrentPage(1);
    setFetching(true);
  }

  useEffect( () => {
    document.addEventListener('scroll', scrollHandler);
    return function(){
      document.removeEventListener('scroll', scrollHandler);
    };
  }, [fetchDevices]);

  const scrollHandler = (e) =>{
    if(e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100 && devices.length < totalCount){
      setFetching(true);
    }
  }

  useEffect(()=>{
    if(fetching){
      fetchDevices();
    }
  }, [fetching])

  useEffect(()=>{
    setFetching(true);
    document.body.classList.remove('modal-open')
  }, [])

  return (
    <div>
        <Back isStart='0' />
        <Header/>
        <Title title='Девайсы'/>
        {deviceError && 
            alert("Произошла ошибка"+{deviceError})
        }
        <Loader loaderVisible={loaderVisible} head={false}/>
        <DevicesElementBlock devices={devices} elementBlockVisible={elementBlockVisible} setVisible={setModalVisible}/>
        <Footer/>
        <DevicesModal visible={modalVisible} setVisible={setModalVisible} updateDevice={updateDevice}/>
    </div>
  )
}

export default Devices
