import React, { useEffect, useState } from 'react'
import Back from '../components/Back'
import Header from '../components/Header'
import Title from '../components/Title'
import FlavoursHead from '../components/flavours-page/FlavoursHead'
import ApiService from '../API/ApiService'
import { useFetching } from '../hooks/useFething'
import Footer from '../components/Footer'
import Loader from '../components/Loader'
import FlavoursElementsBlock from '../components/flavours-page/FlavoursElementsBlock'
import FlavoursModal from '../components/flavours-page/FlavoursModal'

const Flavours = () => {
  const [flavours, setFlavours] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [loaderVisible, setLoaderVisible] = useState('flex');
  const [elementBlockVisible, setElementBlockVisible] = useState('none');
  const [modalVisible, setModalVisible] = useState('none');

  const [fetching, setFetching] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [fetchFlavours, isFlavoursLoading, flavourError] = useFetching( async() => {
    setLoaderVisible('flex');
    const responce = await ApiService.getFlavours(15, currentPage);
    setFlavours([...flavours, ...responce.data]);
    setCurrentPage(prevState => prevState+1);
    setTotalCount(responce.headers['x-total-count']);
    setFetching(false);
    setLoaderVisible('none');
    setElementBlockVisible('flex');
  });

  

  /*const addFlavour = (newflavour) => {
    setFlavours([...flavours, newflavour])
    setFetching(false);
  }

  const patchFlavour = () => {
    setFlavours([]);
    setCurrentPage(1);
    setFetching(true);
  }

  const removeFlavour = (flavour) => {
    setFlavours(flavours.filter(p=> p.id !== flavour))
  }*/

  const updateFlavour = () => {
    setFlavours([]);
    setCurrentPage(1);
    setFetching(true);
  }

  useEffect( () => {
    document.addEventListener('scroll', scrollHandler);
    return function(){
      document.removeEventListener('scroll', scrollHandler);
    };
  }, [fetchFlavours]);

  const scrollHandler = (e) =>{
    if(e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100 && flavours.length < totalCount){
      fetchFlavours(true);
    }
  }

  useEffect(()=>{
    if(fetching){
      fetchFlavours();
    }
  }, [fetching])

  useEffect(()=>{
    setFetching(true);
    document.body.classList.remove('modal-open')
  }, [])

  return (
    <div>
        <Back isStart='0' />
        <Header/>
        <Title title='Ароматизаторы'/>
        <FlavoursHead/>
        {flavourError && 
            alert("Произошла ошибка"+{flavourError})
        }
        <Loader loaderVisible={loaderVisible} head={true}/>
        <FlavoursElementsBlock flavours={flavours} elementBlockVisible={elementBlockVisible} setVisible={setModalVisible}/>
        <Footer/>

        <FlavoursModal visible={modalVisible} setVisible={setModalVisible} updateFlavour={updateFlavour}/>{/* removeFlavour={removeFlavour} addFlavour={addFlavour} patchFlavour={patchFlavour}/>*/}
      </div>
  )
}

export default Flavours
