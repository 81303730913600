import React from 'react'
import ManufacturerElement from './ManufacturerElement'
import {useNavigate} from 'react-router-dom';

const ManufacturerElementsBlock = ({manufacturers, elementBlockVisible, setVisible}) => {
  const router = useNavigate();
  return (
    <div className='pad pad2' style={{display: elementBlockVisible}}>
      <div className='manufacturer-elements-block-plate'>
        {manufacturers.map(manufacturer => 
            <ManufacturerElement manufacturer={manufacturer} key={manufacturer.id} setVisible={setVisible}/>
        )}
        <div className='manufacturer-element' onClick={()=> router(`/manufacturers/new`, setVisible('flex'), document.body.classList.add('modal-open'))}>
          <div className='manufacturer-element-plus-round'>
            <span className='manufacturer-element-plus'>+</span>
          </div>
          <div className='manufacturer-element-plate-name'>
              <div className='manufacturer-element-name'>Добавить элемент</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManufacturerElementsBlock
