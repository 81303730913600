import React from 'react'
import FluidElement from './FluidElement'
import { useNavigate } from 'react-router-dom';

const FluidElementsBlock = ({fluids, elementBlockVisible, setVisible}) => {
  const router = useNavigate();
  return (
    <div className='pad' style={{display: elementBlockVisible}}>
      <div className='fluid-elements-block-plate'>
        {fluids.map((fluid, index) => 
            <FluidElement fluid={fluid} key={fluid.id} num={index+1} setVisible={setVisible}/>
        )}
        <div className='fluid-add_element' onClick={()=> router(`/fluids/new`, setVisible('flex'), document.body.classList.add('modal-open'))}>
          <div className='fluid-add_element-ellipse-round'>
            <div className='fluid-add_element-ellipse-plus'>+</div>
          </div>
          <span className='fluid-add_element-text'>Добавить элемент</span>
        </div>
      </div>
    </div>
  )
}

export default FluidElementsBlock
