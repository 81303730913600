import React from 'react'

const Loader = (props) => {
  return (
    <div>
      {props.head
        ?<div className='loader-plate-base-not-head loader-plate-base-yes-head' style={{display: props.loaderVisible}}><div className='loader-plate'><span class="loader"></span></div></div>
        :<div className='loader-plate-base-not-head' style={{display: props.loaderVisible}}><div className='loader-plate'><span class="loader"></span></div></div>
      }
    </div>
    
  )
}

export default Loader
