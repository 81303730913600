import React, { useState } from 'react'
import SvgM from '../svg/menu.svg';
import { HandySvg } from 'handy-svg'
import { Transition } from 'react-transition-group';
import { Link, NavLink } from 'react-router-dom';

const Header = () => {
    const [menuVisible, setMenuVisible] = useState(false);
  return (
    <div className='header-back'>
    <Link to='/' className='header-logo'><span className='hl-s'>S</span><span className='hl-p'>MOKE STORY</span><span className='hl-m'> - S</span></Link>
    <Transition in={menuVisible} timeout={600}>
        {state => 
            <HandySvg src={SvgM} className={`header-menu-svg ${state}`} onClick={() => setMenuVisible(!menuVisible)} />
        }
    </Transition>
    <Transition in={menuVisible} timeout={300} mountOnEnter unmountOnExit>
        {state => 
            <div className={`header-links-plate header-links-plate-position ${state}`}>
                <div className='header-menu-title'>Меню</div>
                <div className='header-menu-zgl h-m-zgl-1'>Списки</div>
                <div className='header-menu-zgl h-m-zgl-2'>Статистика</div>
                <hr className='header-line h-l-1'></hr>
                <hr className='header-line h-l-2'></hr>
                <div className='header-plate h-p-1'>
                    <NavLink to='/flavours' className='header-link header-link-position'>Ароматизаторы</NavLink>
                    <NavLink to='/fluids' className='header-link header-link-position'>Жидкости</NavLink>
                    <NavLink to='/devices' className='header-link header-link-position'>Девайсы</NavLink>
                </div> 
                <span className='header-separator'>|</span>
                <div className='header-plate h-p-2'>
                    <NavLink to='/buying' className='header-link header-link-position'>Покупки</NavLink>
                    <NavLink to='/manufacturers' className='header-link header-link-position'>Производители</NavLink>
                    <NavLink to='/rating' className='header-link header-link-position'>Оценки</NavLink>
                </div>
            </div>
        }
    </Transition>
    <Transition in={menuVisible} timeout={600} mountOnEnter unmountOnExit>
        {state => 
            <div className={`fog ${state}`}></div>
        }
    </Transition>
    
    </div>
  )
}

export default Header
