import React from 'react'
import { Link } from 'react-router-dom'

const TopButtons = () => {
  return (
    <div className='panelCenter'>
        <div className='topButtons-panel topButtons-panel-position'>
            <Link to='/buying' className='topButtons-button topButtons-button-position'>Покупка</Link>
            <Link to='/manufacturers' className='topButtons-button topButtons-button-position'>Производители</Link>
            <Link to='/rating' className='topButtons-button topButtons-button-position'>Оценки</Link>
        </div>
    </div>
    
  )
}

export default TopButtons
