import React from 'react'

const FluidHead = () => {
  return (
    <div className='pad pad-relative'>
      <div className='pages-head-plate'>
        <div className='fluid-head-text fluid-head-sharp'>#</div>
        <div className='fluid-head-text fluid-head-name'>Имя/Вкус</div>
        <div className='fluid-head-text fluid-head-img'>Изображения</div>
        <div className='fluid-head-text fluid-head-nic'>Никотин</div>
        <div className='fluid-head-text fluid-head-vol'>Объём</div>
        <div className='fluid-head-text fluid-head-prc'>Стоимость</div>
        <div className='fluid-head-text fluid-head-dsc'>Описание</div>
        <div className='fluid-head-text fluid-head-rat'>Рейтинг</div>
        <div className='fluid-head-text fluid-head-buy'>Покупка</div>
      </div>
    </div>
  )
}

export default FluidHead
