import React from 'react'
import FlavoursElement from './FlavoursElement'
import {useNavigate} from 'react-router-dom';

const FlavoursElementsBlock = ({flavours, elementBlockVisible, setVisible}) => {
  const router = useNavigate();
  return (
    <div className='pad' style={{display: elementBlockVisible}}>
      <div className='fluid-elements-block-plate' >
        {flavours.map((flavour, index) => 
            <FlavoursElement flavour={flavour} key={flavour.id} num={index+1} setVisible={setVisible}/>
        )}
        <div className='fluid-add_element' onClick={()=> router(`/flavours/new`, setVisible('flex'), document.body.classList.add('modal-open'))}>
          <div className='fluid-add_element-ellipse-round'>
            <div className='fluid-add_element-ellipse-plus'>+</div>
          </div>
          <span className='fluid-add_element-text'>Добавить элемент</span>
        </div>
      </div>
    </div>
  )
}

export default FlavoursElementsBlock
