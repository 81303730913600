import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer className='footer-plate'><Link to='https://t.me/Victusic' className='footer-text'>Project by Horoshilov Victor</Link></footer>
  )
}

export default Footer
