import React, { useState } from 'react'
import SvgM from '../../svg/menu.svg';
import { HandySvg } from 'handy-svg'
import { Transition } from 'react-transition-group';
import { Link } from 'react-router-dom';
import LogoStart from './Logo-start';

const MenuStart = () => {
    const [menuVisible, setMenuVisible] = useState(false);
  return (
    <div>
        <Transition in={menuVisible} timeout={600}>
            {state => 
                <div className={`menu-back ${state}`}>
                    <Transition in={menuVisible} timeout={600}>
                        {state => 
                            <HandySvg src={SvgM} className={`menu-svg ${state}`} onClick={() => setMenuVisible(!menuVisible)} />
                        }
                    </Transition>
                    <Transition in={menuVisible} timeout={600}>
                        {state => 
                            <div className={`menu-plate ${state}`}>
                                <div className='menu-title menu-title-position'>Меню</div>
                                <div className='menu-head menu-head-position-lists menu-head-position'>Списки</div>
                                <hr className='menu-line menu-line-position-lists'/>
                                <div className='menu-head menu-head-position-stats menu-head-position'>Статистика</div>
                                <hr className='menu-line menu-line-position-stats'/>
                                <div className='menu-pointList list1'>
                                    <Link to='/flavours' className='menu-point menu-point-position'>Ароматизаторы</Link>
                                    <Link to='/fluids' className='menu-point menu-point-position'>Жидкости</Link>
                                    <Link to='/devices' className='menu-point menu-point-position'>Девайсы</Link>
                                </div>
                                <div className='menu-pointList list2'>
                                    <Link to='/buying' className='menu-point menu-point-position'>Покупка</Link>
                                    <Link to='/manufacturers' className='menu-point menu-point-position'>Производители</Link>
                                    <Link to='/rating' className='menu-point menu-point-position'>Оценки</Link>
                                </div>
                            </div>
                        }
                    </Transition>
                </div>
            }
        </Transition>
        <Transition in={menuVisible} timeout={600}>
            {state => 
                <div className='v-center v-center-position'><hr className={`rLine ${state}`}/></div>
            }
        </Transition>
        <Transition in={menuVisible} timeout={600}>
            {state => 
                <div className={`menu-logoVisible ${state}`}>
                    <LogoStart/>
                </div>
            }
        </Transition>
        <Transition in={menuVisible} timeout={600} mountOnEnter unmountOnExit>
        {state => 
            <div className={`fog ${state}`}></div>
        }
        </Transition>
    </div>
    
    
    
  )
}

export default MenuStart
