import React, { useRef } from 'react'

const UImage = ({value, alt, ...props}) => {

  const filePicker = useRef(null);

  const handleClick = () => {
    filePicker.current.click();
  }

  return (
    <div>
      <input type='file' ref={filePicker} className='ui-image-hidden' accept=".png" {...props}/>
        <div className='ui-image' onClick={handleClick}>
        {value
          ?<img className='ui-image-img' src={value} alt={alt}/>
          :
          <div className='ui-image-pad'>
            <div className='ui-image-round'>
              <span className='ui-image-plus'>+</span>
            </div>
            <span className='ui-image-add'>Добавить изображение</span>
          </div>
        }
        
      </div>
    </div>
  )
}

export default UImage
