import React from 'react'
import BuyingElement from './BuyingElement'

const BuyingElementBlock = ({buyings, maxCount}) => {
  return (
    <div className='pad pad2'>
      <div className='byuing-elements-block-plate'>
        {buyings.map((buying, index) => 
            <BuyingElement buying={buying} maxCount={maxCount} key={index} num={index+1}/>
        )}
      </div>
    </div>
  )
}

export default BuyingElementBlock
