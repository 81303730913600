import React from 'react'
import styled, { keyframes } from 'styled-components'

const RatingElement = (props) => {
    const pulse = keyframes` from {width: 0;}`;
    const ColorPlate = styled.div`animation: ${pulse} 2.5s ease-in-out;`
    return (
      <div className='byuing-element-plate' >
        <ColorPlate className='byuing-element-color-plate' style={{background: props.rating.color+'a7', width:(props.rating.rating*10)+'%'}}>
          <span className='byuing-element-number'>{props.num}</span>
          <img className='byuing-element-img' src={'https://db.smoke-story.site/images/manufacturers/'+props.rating.image} alt={props.rating.name}></img>
          <span className='byuing-element-name'>{props.rating.name}</span>
          <div className='byuing-element-zone'><span className='byuing-element-color'>{props.rating.rating}</span></div>
        </ColorPlate>
      </div>
    )
}

export default RatingElement
