import React from 'react'
import {useNavigate} from 'react-router-dom';

const ManufacturerElement = (props) => {
  const router = useNavigate();
  return (
    <div className='manufacturer-element' onClick={()=> router(`/manufacturers/${props.manufacturer.id}`, props.setVisible('flex'), document.body.classList.add('modal-open'))}>
        <img className='manufacturer-element-img' src={'https://db.smoke-story.site/images/manufacturers/'+props.manufacturer.Image} alt={props.manufacturer.Name}></img>
        <div className='manufacturer-element-plate-name'>
            <div className='manufacturer-element-name' style={{color: props.manufacturer.Color}}>{props.manufacturer.Name}</div>
        </div>
    </div>
  )
}

export default ManufacturerElement
