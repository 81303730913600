import React, { useEffect, useState } from 'react'
import Back from '../components/Back'
import Header from '../components/Header'
import Title from '../components/Title'
import Footer from '../components/Footer'
import ManufacturerElementsBlock from '../components/manufacturers-page/ManufacturerElementsBlock'
import ApiService from '../API/ApiService'
import Loader from '../components/Loader'
import { useFetching } from '../hooks/useFething'
import ManufacturerModal from '../components/manufacturers-page/ManufacturerModal'
import {useParams} from 'react-router-dom';

const Manufacturers = () => {
  const params = useParams();
  const [manufacturers, setManufacturers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [loaderVisible, setLoaderVisible] = useState('flex');
  const [elementBlockVisible, setElementBlockVisible] = useState('none');
  const [modalVisible, setModalVisible] = useState('none');
  
  const [fetching, setFetching] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [fetchManufacturers, isManufacturersLoading, manufacturerError] = useFetching( async() => {
    setLoaderVisible('flex');
    const responce = await ApiService.getManufacturers(14, currentPage);
    setManufacturers([...manufacturers, ...responce.data]);
    setCurrentPage(prevState => prevState+1);
    setTotalCount(responce.headers['x-total-count']);
    setFetching(false);
    setLoaderVisible('none');
    setElementBlockVisible('flex');
    if(params.id){
      setModalVisible('flex');
    }
  });

  const updateManufacturer = () => {
    setManufacturers([]);
    setCurrentPage(1);
    setFetching(true);
  }

  useEffect( () => {
    document.addEventListener('scroll', scrollHandler);
    return function(){
      document.removeEventListener('scroll', scrollHandler);
    };
  }, [fetchManufacturers]);

  const scrollHandler = (e) =>{
    if(e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100 && manufacturers.length < totalCount){
      setFetching(true);
    }
  }

  useEffect(()=>{
    if(fetching){
      fetchManufacturers();
    } 
  }, [fetching])

  useEffect(()=>{
    setFetching(true);
    document.body.classList.remove('modal-open')
  }, [])

  return (
    <div>
        <Back isStart='0' />
        <Header/>
        <Title title='Производители'/>
        {manufacturerError && 
            alert("Произошла ошибка"+{manufacturerError})
        }
        <Loader loaderVisible={loaderVisible} head={false}/>
        <ManufacturerElementsBlock manufacturers={manufacturers} elementBlockVisible={elementBlockVisible} setVisible={setModalVisible}/>
        <Footer/>
        <ManufacturerModal visible={modalVisible} setVisible={setModalVisible} updateManufacturer={updateManufacturer}/>
    </div>
  )
}

export default Manufacturers
