import React, { useEffect, useState } from 'react'
import Back from '../components/Back'
import Header from '../components/Header'
import Title from '../components/Title'
import FluidHead from '../components/fluids-page/FluidHead'
import FluidElementsBlock from '../components/fluids-page/FluidElementsBlock'
import ApiService from '../API/ApiService'
import { useFetching } from '../hooks/useFething'
import Footer from '../components/Footer'
import Loader from '../components/Loader'
import FluidsModal from '../components/fluids-page/FluidsModal'
import {useParams} from 'react-router-dom';

const Fluids = () => {
  const params = useParams();
  const [fluids, setFluids] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [loaderVisible, setLoaderVisible] = useState('flex');
  const [elementBlockVisible, setElementBlockVisible] = useState('none');
  const [modalVisible, setModalVisible] = useState('none');
  
  const [fetching, setFetching] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [fetchFluids, isRatingsLoading, fluidError] = useFetching( async() => {
    setLoaderVisible('flex');
    const responce = await ApiService.getFluids(15, currentPage);
    setFluids([...fluids, ...responce.data]);
    setCurrentPage(prevState => prevState+1);
    setTotalCount(responce.headers['x-total-count']);
    setFetching(false);
    setLoaderVisible('none');
    setElementBlockVisible('flex');
    if(params.id){
      setModalVisible('flex');
    }
  });

  const updateFluid = () => {
    setFluids([]);
    setCurrentPage(1);
    setFetching(true);
  }

  useEffect( () => {
    document.addEventListener('scroll', scrollHandler);
    return function(){
      document.removeEventListener('scroll', scrollHandler);
    };
  }, [fetchFluids]);

  const scrollHandler = (e) =>{
    if(e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100 && fluids.length < totalCount){
      setFetching(true);
    }
  }

  useEffect(()=>{
    if(fetching){
      fetchFluids();
    }
  }, [fetching])

  useEffect(()=>{
    setFetching(true);
    document.body.classList.remove('modal-open')
  }, [])
  
  return (
    <div>
        <Back isStart='0' />
        <Header/>
        <Title title='Жидкости'/>
        <FluidHead/>
        {fluidError && 
            alert("Произошла ошибка"+{fluidError})
        }
        <Loader loaderVisible={loaderVisible} head={true}/>
        <FluidElementsBlock fluids={fluids} elementBlockVisible={elementBlockVisible} setVisible={setModalVisible}/>
        <Footer/>

        <FluidsModal visible={modalVisible} setVisible={setModalVisible} updateFluid={updateFluid}/>
      </div>
  )
}

export default Fluids
