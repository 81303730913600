import React, { useEffect, useState } from 'react'
import {useNavigate, useParams} from 'react-router-dom';
import UName from '../UI/UName'
import UInputName from '../UI/UInputName'
import UInput from '../UI/UInput'
import UArea from '../UI/UArea'
import UButton from '../UI/UButton'
import UImage from '../UI/UImage'
import ApiService from '../../API/ApiService';
import { ChromePicker } from 'react-color';

const ManufacturerModal = ({visible, setVisible, updateManufacturer}) => {
    const router = useNavigate();
    const params = useParams();
    const [isNewManufacturer, setIsNewManufacturer] = useState(true);
    const [isColorPicker, setIsColorPicker] = useState(false);
    const [colorPickerVisible, setColorPickerVisible] = useState('none');

    const [newManufacturer, setNewManufacturer] = useState({Name: '', Color: '', Image: ''});
    const [inputCode, setInputCode] = useState('');
    const [inputImage, setInputImage] = useState('');

    const fetching = async () => {
        if(params.id && params.id === 'new'){
            setIsNewManufacturer(true);
        }
        else if(params.id){
            const response = await ApiService.getManufacturersId(params.id);
            setNewManufacturer({Name: response.data.Name, Color: response.data.Color, Image: 'https://db.smoke-story.site/images/manufacturers/'+response.data.Image})
            setIsNewManufacturer(false);
            setInputImage('');
        }
    }
    
    const deleteTab = async (e) =>{
        e.preventDefault();
        if(!inputCode){
            alert('Укажите код для работы запроса');
        }
        else{
            const response = await ApiService.deleteManufacturers(params.id, inputCode);
            if(!response){
                alert('Произошла ошибка при выполнении запроса, проверьте правильность кода');
            }
            else{
                router(`/manufacturers`);
                setVisible('none');
                updateManufacturer();
            }
        }
    }

    const workTab = async (e) =>{
        e.preventDefault();
        if (newManufacturer.Name && inputCode && newManufacturer.Color){
            if(isNewManufacturer){
                if(inputImage){
                    const bodyFormData = new FormData();
                    bodyFormData.append('id', '');
                    bodyFormData.append('Name', newManufacturer.Name);
                    bodyFormData.append('Color', newManufacturer.Color);
                    bodyFormData.append('Image', inputImage);
                    const response = await ApiService.postManufacturers(bodyFormData, inputCode);
                    if(!response){
                        alert('Произошла ошибка при выполнении запроса, проверьте правильность кода');
                    }
                    else{
                        router(`/manufacturers`);
                        setVisible('none');
                        updateManufacturer();
                    }
                }
                else{
                    alert('Добавьте изображение');
                }
            }
            else{
                const PatchBody = {
                    Name: newManufacturer.Name,
                    Color: newManufacturer.Color,
                    Image: inputImage
                };
                const response = await ApiService.patchManufacturers(PatchBody, params.id, inputCode);
                if(!response){
                    alert('Произошла ошибка при выполнении запроса, проверьте правильность кода');
                }
                else{
                    router(`/manufacturers`);
                    setVisible('none');
                    updateManufacturer();
                }
            }
        }
        else{
            alert('Заполните все данные или проверьте правильность их заполнения');
        }
    }

    const handleChange = (e) =>{
        const file = e.target.files[0];
        const reader  = new FileReader();
        if (file) {reader.readAsDataURL(file)}
        
        reader.onloadend = function () {
            setNewManufacturer({...newManufacturer, Image: reader.result})
            setInputImage(reader.result);
        }
      
        
    }

    useEffect(()=>{
        setNewManufacturer({Name: '', Color: '', Image: ''})
        fetching();
    }, [visible])

    const onColorPickerInfoChange = color => {
        setNewManufacturer({...newManufacturer, Color: color.hex})
    };

    const close =  () => {
        if(!isColorPicker){
            router(`/manufacturers`)
            setVisible('none')
            setColorPickerVisible('none')
            document.body.classList.remove('modal-open')
        }
    }

    const ColorClick = () =>{
        {colorPickerVisible === 'flex'
            ?setColorPickerVisible('none')
            :setColorPickerVisible('flex')
        }
        setIsColorPicker(!isColorPicker)
    }

  return (
    <div className='modalFog' style={{display: visible}} onClick={close}>
        <div className='ui-color-picker' style={{display: colorPickerVisible}}><div className='ui-color-picker-e'><ChromePicker disableAlpha={true} color={newManufacturer.Color} onClick={e => e.preventDefault()} onChange={onColorPickerInfoChange} displayChromePicker={false}/></div></div>
      
      <div className='modal-plate' onClick={(e) =>e.stopPropagation()}>
            <UName name='Производитель'/>
            <form>
                <UInputName placeholder='Имя' type='text' value={newManufacturer.Name} onChange={e => setNewManufacturer({...newManufacturer, Name: e.target.value})}/>
                <div className='ui-flex-flavours'>
                    <div className='ui-flex-left-image'>
                        <div className='ui-color-pad'>
                            <UArea placeholder='Цвет' style={{width: '100%', height: '100%', marginBottom: '22px'}} type='text' value={newManufacturer.Color} onChange={e => setNewManufacturer({...newManufacturer, Color: e.target.value})}/>
                            <div className='ui-color-block' style={{background: newManufacturer.Color}} onClick={ColorClick}></div>
                        </div>
                        <UInput placeholder='Код' style={{minHeight: '30px'}} type='password' value={inputCode} onChange={e => setInputCode(e.target.value)}/>
                    </div>
                    <UImage value={newManufacturer.Image} onChange={handleChange} alt={newManufacturer.Name}/>
                </div>
                <div className='ui-button-plate'>
                    {isNewManufacturer
                        ?<span></span>
                        :<UButton onClick={deleteTab}>Удалить</UButton>
                    }
                    <UButton onClick={workTab}>Сохранить</UButton>
                </div>
            </form>
        </div>
    </div>
  )
}

export default ManufacturerModal
