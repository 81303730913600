import axios from "axios";

export default class ApiService{
    //Fluids
    static async getFluids(limit, page){
        return await axios.get('https://db.smoke-story.site/fluids', {params:{ limit: limit, page: page }});
    }
    static async getFluidsId(id){        
        return await axios.get('https://db.smoke-story.site/fluids/' + id);
    }
    static async postFluids(bodyFormData, token){
        try{
            return await axios.post('https://db.smoke-story.site/fluids', bodyFormData, {headers: {'content-type': 'multipart/form-data', 'X-API-KEY': token}});
        }catch (e){return(false);}
    }
    static async patchFluids(body, id, token){
        try{
            return await axios.patch('https://db.smoke-story.site/fluids/' + id, JSON.stringify(body), {headers: {'content-type': 'application/json', 'X-API-KEY': token}});
        }catch (e){return(false);}
    }
    static async deleteFluids(id, token){
        try{
            return await axios.delete('https://db.smoke-story.site/fluids/' + id, {headers: {'X-API-KEY': token}});
        }catch (e){return(false);}
    }

    //Flavours
    static async getFlavours(limit, page){
        return await axios.get('https://db.smoke-story.site/flavorings', {params:{ limit: limit, page: page }});
    }
    static async getFlavoursId(id){        
        return await axios.get('https://db.smoke-story.site/flavorings/' + id);
    }
    static async postFlavours(bodyFormData, token){
        try{
            return await axios.post('https://db.smoke-story.site/flavorings', bodyFormData, {headers: {'content-type': 'multipart/form-data', 'X-API-KEY': token}});
        }catch (e){return(false);}
    }
    static async patchFlavours(body, id, token){
        try{
            return await axios.patch('https://db.smoke-story.site/flavorings/' + id, JSON.stringify(body), {headers: {'content-type': 'application/json', 'X-API-KEY': token}});
        }catch (e){return(false);}
    }
    static async deleteFlavours(id, token){
        try{
            return await axios.delete('https://db.smoke-story.site/flavorings/' + id, {headers: {'X-API-KEY': token}});
        }catch (e){return(false);}
    }

    //Manufacturers
    static async getManufacturersFull(){
        return await axios.get('https://db.smoke-story.site/manufacturers');
    }
    static async getManufacturers(limit, page){
        const response = await axios.get('https://db.smoke-story.site/manufacturers', {params:{ limit: limit, page: page }});
        return response;
    }
    static async getManufacturersId(id){        
        return await axios.get('https://db.smoke-story.site/manufacturers/' + id);
    }
    static async postManufacturers(bodyFormData, token){
        try{
            return await axios.post('https://db.smoke-story.site/manufacturers', bodyFormData, {headers: {'content-type': 'multipart/form-data', 'X-API-KEY': token}});
        }catch (e){return(false);}
    }
    static async patchManufacturers(body, id, token){
        try{
            return await axios.patch('https://db.smoke-story.site/manufacturers/' + id, JSON.stringify(body), {headers: {'content-type': 'application/json', 'X-API-KEY': token}});
        }catch (e){return(false);}
    }
    static async deleteManufacturers(id, token){
        try{
            return await axios.delete('https://db.smoke-story.site/manufacturers/' + id, {headers: {'X-API-KEY': token}});
        }catch (e){return(false);}
    }

    //Devices
    static async getDevices(limit, page){
        const response = await axios.get('https://db.smoke-story.site/devices', {params:{ limit: limit, page: page }});
        return response;
    }
    static async getDevicesId(id){        
        return await axios.get('https://db.smoke-story.site/devices/' + id);
    }
    static async postDevices(bodyFormData, token){
        try{
            return await axios.post('https://db.smoke-story.site/devices', bodyFormData, {headers: {'content-type': 'multipart/form-data', 'X-API-KEY': token}});
        }catch (e){return(false);}
    }
    static async patchDevices(body, id, token){
        try{
            return await axios.patch('https://db.smoke-story.site/devices/' + id, JSON.stringify(body), {headers: {'content-type': 'application/json', 'X-API-KEY': token}});
        }catch (e){return(false);}
    }
    static async deleteDevices(id, token){
        try{
            return await axios.delete('https://db.smoke-story.site/devices/' + id, {headers: {'X-API-KEY': token}});
        }catch (e){return(false);}
    }

    //Other
    static async getBuying(){
        const response = await axios.get('https://db.smoke-story.site/buying');
        return response;
    }
    static async getRating(){
        const response = await axios.get('https://db.smoke-story.site/rating');
        return response;
    }
    
}